import http from "@/utils/services/http"
import {download} from "@/domain/leiloes/services/financeiro";

export const stats = () => {
    let url = `/api/financeiro/stats`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const listConta = (limit, page, filtros, exportar = false) => {
    let url = '/api/financeiro?page=' + page + '&limit=' + limit + filtros
    let config = {}
    if (exportar){
        config.responseType = 'blob'
    }
    return http.get(url, config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const findConta = (id) => {
    let url = `/api/financeiro/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newConta = (data) => {
    let url = `/api/financeiro`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateConta = (conta, method = 'PUT') => {
    let url = `/api/financeiro/${conta.id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, conta)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteConta = (id) => {
    let url = `/api/financeiro/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}


export const uploadArquivoConta = (id, data) => {
    let url = `/api/financeiro/${id}/arquivos`
    return http.post(url, data, {
        'timeout': 300000
        // headers: {'Content-Type': 'multipart/form-data'}
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const downloadArquivoConta = (movimentacao, arquivo, inline = false) => {
    let url = `/api/financeiro/${movimentacao.id}/arquivos/${arquivo}/download`
    if (inline) {
        url = url + '?inline=true'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const excluirArquivoConta = (contaId, arquivo) => {
    let url = `/api/financeiro/${contaId}/arquivos/${arquivo}/remove`

    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const findDescricao = (desc) => {
    let url = `/api/financeiro/findDescricao?terms=${desc}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const recibo = (id, enviar = false) => {
    let url = `/api/financeiro/${id}/recibo`
    if (enviar) {
        url = url + '?enviar=1'
    }
    return download(url)
}

export const fatura = (id) => {
    let url = `/api/financeiro/${id}/fatura`
    return download(url)
}

export const cobrar = (id) => {
    let url = `/api/financeiro/${id}/cobrar`
    return http.post(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
