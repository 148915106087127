<script>
import SlLoading from "components/layout/components/Loading.vue"
import DotLoading from "components/layout/components/DotLoading.vue"
import {atualizaStatusBemGrupoPorto} from "@/domain/bem/services/marketplace"

export default {
  name: "BemGrupoPortoIntegra",
  components: {DotLoading, SlLoading},
  props: {
    bem: {
      required: true
    }
  },
  data () {
    return {
      prevent: false,
      loading: false
    }
  },
  methods: {
    atualizaStatusBem () {
      this.prevent = true
      this.loading = true
      atualizaStatusBemGrupoPorto(this.bem.id)
          .then(response => {
            if (!this.bem.extra) {
              this.bem.extra = {}
            }
            if (!response.data.error) {
              this.bem.extra.grupoPorto = response.data
            }
            this.loading = false
            setTimeout(() => {
              this.prevent = false
            }, 1000)
          })
          .catch(error => {
            this.loading = false
            setTimeout(() => {
              this.prevent = false
            }, 1000)
            this.alertApiError(error)
          })
    }
  }
}
</script>

<template>
  <div>
    <h3 class="dtitle">Grupo Porto</h3>
    <div class="m-t">
      <div v-if="loading" class="m-t">
        <div class="flex items-center"><sl-loading content="" class="size-18 m-r-sm" /> Conectando com a Imóvel Web, aguarde, isto pode levar alguns segundos<dot-loading :limit="10" /></div>
      </div>
      <div class="m-b">
        <u-btn @click="atualizaStatusBem" color="green" label="Transmitir status do bem via API" no-caps />
      </div>
      <div class="m-b">Retorno API:</div>
      <div v-if="!prevent" style="width: 100%; height: 140px; overflow: auto">
        <pre>{{bem.extra.grupoPorto}}</pre>
      </div>
    </div>
  </div>
</template>
